import { createActionsHook } from 'react-sweet-state';

import { createDataHook } from '@intelliarts/ia-react-template';

import { AccessCardsStore } from './accessCardsStore';
import { areEntitiesEmpty } from '../../../utils';
import { useUsersView } from '../../User/store/usersSelectors';
import { UserView } from '../../User/models/UserView';
import { AccessCardView } from '../models/AccessCard';

export const useAccessCardsViews = (): AccessCardView[] => {
  const userViews = useUsersView();
  return useAccessCardsViewsSelector(userViews);
}

export const getAccessCardsView = (accessCards: AccessCardView[], users: UserView[]): AccessCardView[] => {
  if (areEntitiesEmpty(accessCards) || areEntitiesEmpty(users)) return [];
  return accessCards
    .map((ac) => {
      const user = users.find(user => user.id === ac.userId);
      return ({
        ...ac,
        userId: ac.userId || null,
        fullName: user ? `${user.givenName} ${user.familyName}` : '',
      });
    })
};

export const useAccessCardsViewsSelector = createDataHook(AccessCardsStore, getAccessCardsView)

export const useAccessCardsActions = createActionsHook(AccessCardsStore);
