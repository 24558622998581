import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessCardView } from '../components/AccessCards/models/AccessCard';
import { User } from '../components/User/models/User';
import { UserView } from '../components/User/models/UserView';

export const areEntitiesEmpty = (entities: User[] | UserView[] | AccessCardView[]) => {
  return entities === null || (Array.isArray(entities) && entities.length === 0);
}

/**
 * Column's `headerName` is used as default item of the column
 */
export const useTranslateDataGridColumn = (rootListLocalePath: string, defaultItem = 'headerName') => {
  const { t } = useTranslation();

  return useCallback(
    (
      val: string,
      args = {},
      columnItem = defaultItem,
    ) => {
      return t(`${rootListLocalePath}.columns.${val}.${columnItem}`, args);
    },
    [ t, defaultItem ]);
};